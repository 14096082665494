@tailwind base;
@tailwind components;
@tailwind utilities;

/* ================ MAIN ================ */

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ================ FONTS LOAD ================ */

@font-face {
  font-family: LuxeUnoLight;
  src: url(../assets/fonts/LuxeUno-Light.woff);
}

@font-face {
  font-family: LuxeUnoRegular;
  src: url(../assets/fonts/LuxeUno-Regular.woff);
}

@font-face {
  font-family: LuxeUnoSemiBold;
  src: url(../assets/fonts/LuxeUno-SemiBold.woff);
}

@font-face {
  font-family: LuxeUnoBold;
  src: url(../assets/fonts/LuxeUno-Bold.woff);
}

/* ================ FONTS ================ */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: LuxeUnoRegular !important;
}

.fontRegular {
  font-family: LuxeUnoRegular !important;

}

.fontLight {
  font-family: LuxeUnoLight !important;

}

.font-light {
  font-family: LuxeUnoLight !important;
}

.fontSemiBold {
  font-family: LuxeUnoSemiBold !important;
}

* {
  font-family: LuxeUnoLight;
}

.capitalize {
  text-transform: capitalize;
}

/* ================ COLORS ================ */

.blackColor {
  color: #111111;
}

.blackColorBg {
  background-color: #111111;
}

.mainColor {
  color: #6f00d8;
}

.mainColorBg {
  background-color: #6f00d8;
}

.additionalColor {
  color: rgb(34, 34, 34);
}

.additionalColorBg {
  background-color: rgb(34, 34, 34);
}

.grayColor {
  background-color: #4e4e4e96;
}

/* ================ HEADINGS ================ */

.whiteHeading {
  background-clip: text;
  padding: 10px 0px;
  color: transparent;
  background-image: linear-gradient(to right, #f0f0f0, #e6e6e6 80%);
}

.colorHeading {
  background: linear-gradient(151deg, rgba(111, 0, 216, 1) 0%, rgb(76, 1, 146) 100%);
  background-clip: text;
  color: transparent;
  padding: 10px 5px;
  margin-left: -5px;
  margin-right: -5px;
}

.colorHeadingFlip {
  background: linear-gradient(151deg, rgba(111, 0, 216, 1) 0%, rgb(76, 1, 146) 100%);
  background-clip: text;
  color: transparent;
  padding: 10px 5px;
  margin-left: -5px;
  margin-right: -5px;
}

/* ================ BUTTONS ================ */

.firstButton {
  background: rgb(111, 0, 216);
  background: -moz-linear-gradient(151deg, rgba(111, 0, 216, 1) 0%, rgba(60, 0, 117, 1) 100%);
  background: -webkit-linear-gradient(151deg, rgba(111, 0, 216, 1) 0%, rgba(60, 0, 117, 1) 100%);
  background: linear-gradient(151deg, rgba(111, 0, 216, 1) 0%, rgba(60, 0, 117, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6f00d8", endColorstr="#3c0075", GradientType=1);
  color: #ffffff;
  border: none;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border-radius: 25px;
}

.firstButton:hover {
  opacity: 0.5;
  transition: 0.3s ease-in-out;
}

.secondButton {
  background-color: #ffffff;
  color: #6f00d8;
  border: none;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border-radius: 25px;
}

.secondButton:hover {
  background-color: #6f00d8;
  color: #ffffff;
  transition: 0.3s ease-in-out;
}

.secondButton:hover path {
  stroke: #6f00d8;
  transition: 0.3s ease-in-out;
}

.secondButton:hover path {
  stroke: #ffffff;
  transition: 0.3s ease-in-out;
}

.thirdButton {
  background-color: transparent;
  color: #6f00d8;
  border: none;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #ffffff;
  transition: 0.3s ease-in-out;
  border-radius: 25px;
}

.thirdButton:hover {
  background-color: #6f00d8;
  border: 1px solid #6f00d8;
  color: #ffffff;
  transition: 0.3s ease-in-out;
}

.thirdButton:hover path {
  stroke: #6f00d8;
  transition: 0.3s ease-in-out;
}

.thirdButton:hover path {
  stroke: #ffffff;
  transition: 0.3s ease-in-out;
}

/* ================ LINKS ================ */

.headerLink {
  color: white;
  position: relative;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.headerLink:hover {
  color: #6f00d8;
  transition: 0.3s ease-in-out;
}

.headerLink::before,
.headerLink::after {
  content: "";
  position: absolute;
  bottom: -3px;
  height: 2px;
  width: 0;
  background-color: #6f00d8;
  transition: width 0.3s ease-in-out;
}

.headerLink::before {
  left: 0;
}

.headerLink::after {
  right: 0;
}

.headerLink:hover::before,
.headerLink:hover::after {
  width: 50%;
}

.footerLink {
  color: #ffffff;
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.footerLink:hover {
  color: #6f00d8;
  transition: 0.3s ease-in-out;
}

.infoBannerLink {
  color: white;
  position: relative;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.infoBannerLink::before,
.infoBannerLink::after {
  content: "";
  position: absolute;
  bottom: -3px;
  height: 2px;
  width: 0;
  background-color: #ffffff;
  transition: width 0.3s ease-in-out;
}

.infoBannerLink::before {
  left: 0;
}

.infoBannerLink::after {
  right: 0;
}

.infoBannerLink:hover::before,
.infoBannerLink:hover::after {
  width: 50%;
}

.headerLinkPurple {
  color: #6f00d8;
  position: relative;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.headerLinkPurple:hover {
  color: #6f00d8;
  transition: 0.3s ease-in-out;
}

.headerLinkPurple::before,
.headerLinkPurple::after {
  content: "";
  position: absolute;
  bottom: -3px;
  height: 2px;
  width: 0;
  background-color: #6f00d8;
  transition: width 0.3s ease-in-out;
}

.headerLinkPurple::before {
  left: 0;
}

.headerLinkPurple::after {
  right: 0;
}

.headerLinkPurple:hover::before,
.headerLinkPurple:hover::after {
  width: 50%;
}

/* ================ SHADOWS ================ */

.firstShadow {
  -webkit-box-shadow: 0px -15px 1000px 0px rgba(111, 0, 216, 1);
  -moz-box-shadow: 0px -15px 1000px 0px rgba(111, 0, 216, 1);
  box-shadow: 0px -15px 1000px 0px rgba(111, 0, 216, 1);
}

.firstShadowLight {
  -webkit-box-shadow: 0px -15px 50px 0px rgba(111, 0, 216, 0.3);
  -moz-box-shadow: 0px -15px 50px 0px rgba(111, 0, 216, 0.3);
  box-shadow: 0px -15px 50px 0px rgba(111, 0, 216, 0.3);
}

.firstShadowLightWhite {
  -webkit-box-shadow: 0px -0px 25px 0px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 0px -0px 25px 0px rgba(255, 255, 255, 0.5);
  box-shadow: 0px -0px 25px 0px rgba(255, 255, 255, 0.5);
}

.firstShadowDrop {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6f00d8", endColorstr="#3c0075", GradientType=1);
  filter: drop-shadow(0px -15px 100px rgba(111, 0, 216, 1));
}

.firstShadowDropLight {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6f00d8", endColorstr="#3c0075", GradientType=1);
  filter: drop-shadow(0px -15px 50px rgba(111, 0, 216, 0.3));
}

/* ================ GRADIENTS ================ */

.logoSliderGradient {
  background: rgb(17, 17, 17);
  background: -moz-linear-gradient(90deg, rgba(17, 17, 17, 1) 5%, rgba(56, 0, 108, 0) 50%, rgba(17, 17, 17, 1) 95%);
  background: -webkit-linear-gradient(90deg, rgba(17, 17, 17, 1) 5%, rgba(56, 0, 108, 0) 50%, rgba(17, 17, 17, 1) 95%);
  background: linear-gradient(90deg, rgba(17, 17, 17, 1) 5%, rgba(56, 0, 108, 0) 50%, rgba(17, 17, 17, 1) 95%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#111111", endColorstr="#111111", GradientType=1);
}

.testimonialGradient {
  background: rgb(17, 17, 17);
  background: -moz-linear-gradient(0deg, rgba(17, 17, 17, 1) 5%, rgba(17, 17, 17, 0) 100%);
  background: -webkit-linear-gradient(0deg, rgba(17, 17, 17, 1) 5%, rgba(17, 17, 17, 0) 100%);
  background: linear-gradient(0deg, rgba(17, 17, 17, 1) 5%, rgba(17, 17, 17, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#111111", endColorstr="#111111", GradientType=1);
}

.testimonialGradientColor {
  background: rgb(17, 17, 17);
  background: -moz-linear-gradient(0deg, rgba(111, 0, 216, 1) 5%, rgba(111, 0, 216, 0) 100%);
  background: -webkit-linear-gradient(0deg, rgba(111, 0, 216, 1) 5%, rgba(111, 0, 216, 0) 100%);
  background: linear-gradient(0deg, rgba(111, 0, 216, 1) 5%, rgba(111, 0, 216, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#111111", endColorstr="#111111", GradientType=1);
}

.mixedGradients {
  background: rgb(111, 0, 216);
  background: -moz-linear-gradient(151deg, rgba(111, 0, 216, 1) 0%, rgba(60, 0, 117, 1) 100%);
  background: -webkit-linear-gradient(151deg, rgba(111, 0, 216, 1) 0%, rgba(60, 0, 117, 1) 100%);
  background: linear-gradient(151deg, rgba(111, 0, 216, 1) 0%, rgba(60, 0, 117, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6f00d8", endColorstr="#3c0075", GradientType=1);
}

.letsofferGradient {
  background: rgb(17, 17, 17);
  background: -moz-linear-gradient(0deg, rgba(17, 17, 17, 1) 0%, rgba(17, 0, 33, 1) 25%, rgba(17, 17, 17, 1) 50%, rgba(17, 0, 33, 1) 75%, rgba(17, 17, 17, 1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(17, 17, 17, 1) 0%, rgba(17, 0, 33, 1) 25%, rgba(17, 17, 17, 1) 50%, rgba(17, 0, 33, 1) 75%, rgba(17, 17, 17, 1) 100%);
  background: linear-gradient(0deg, rgba(17, 17, 17, 1) 0%, rgba(17, 0, 33, 1) 25%, rgba(17, 17, 17, 1) 50%, rgba(17, 0, 33, 1) 75%, rgba(17, 17, 17, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#111111", endColorstr="#111111", GradientType=1);
}

.btt-gradient-black {
  background: rgb(255,255,255);
  background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 20%, rgba(17,17,17,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 20%, rgba(17,17,17,1) 100%);
  background: linear-gradient(180deg, rgba(255,255,255,0) 20%, rgba(17,17,17,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#111111",GradientType=1);
}

.gradient-lowerrisk {
  background: rgb(255, 255, 255);
  background: -moz-radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 0%, rgba(17, 17, 17, 1) 80%);
  background: -webkit-radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 0%, rgba(17, 17, 17, 1) 80%);
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 0%, rgba(17, 17, 17, 1) 80%);
  background-position: center;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#111111", GradientType=1);
}

.whiteGradients {
  background-image: linear-gradient(to right, #f0f0f0, #e6e6e6 80%);
}

/* ================ BORDERS ================ */

.lightWhite {
  border: 1px solid #ffffff19;
}

.lightWhiteRight {
  border-right: 1px solid #ffffff19;
}

/* ================ LIGHTS ================ */

.mainLight {
  -webkit-box-shadow: 0px -200px 600px 20px rgba(111, 0, 216, 1);
  -moz-box-shadow: 0px -200px 600px 20px rgba(111, 0, 216, 1);
  box-shadow: 0px -200px 600px 20px rgba(111, 0, 216, 1);
}

.grayLight {
  background: rgb(17, 17, 17);
  background: -moz-linear-gradient(151deg, rgba(17, 17, 17, 1) 0%, rgba(47, 47, 47, 1) 100%);
  background: -webkit-linear-gradient(151deg, rgba(17, 17, 17, 1) 0%, rgba(47, 47, 47, 1) 100%);
  background: linear-gradient(151deg, rgba(17, 17, 17, 1) 0%, rgba(47, 47, 47, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#111111", endColorstr="#2f2f2f", GradientType=1);
}

/* ================ SOCIAL MEDIA ================ */

.footerSocial {
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid rgba(255, 255, 255, 0.41);
}

.footerSocial::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  transform: translateY(100%);
  transition: transform 0.3s, background-color 0.3s;
}

.footerSocial svg {
  position: relative;
  color: white;
  transition: color 0.3s;
  z-index: 1;
}

.footerSocial path {
  fill: #ffffff;
  transition: fill 0.3s;
}

.footerSocial:hover::before {
  transform: translateY(0);
  background-color: white;
}

.footerSocial:hover path {
  fill: #000000;
  transition: fill 0.3s;
}

/* ================ OFFER CARDS ================ */

.offerCard .firstView {
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.offerCard .secondView {
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.offerCard:hover .firstView {
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.offerCard:hover .secondView {
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.offerCard .overlay {
  opacity: 0.4;
}

.offerCard div {
  backdrop-filter: blur(0px);
  transition: 0.3s ease-in-out;
}

.offerCard:hover div {
  backdrop-filter: blur(5px);
  transition: 0.3s ease-in-out;
}

/* ================ PROBLEMS ================ */

.progress-bar {
  position: absolute;
  z-index: 999;
  width: 100%;
  top: 0;
  height: 100%;
  background: #6f00d8;
  transform-origin: 0%;
}

/* ================ INDUSTRIES ================ */

.industryBox .industryOverlay {
  opacity: 0.5;
}

.industryBox:hover .industryOverlay {
  opacity: 0.8;
}

/* ================ FAQ ================ */

.faqBox {
  background-color: #111111;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.faqBox:hover {
  transition: 0.3s ease-in-out;
  -webkit-box-shadow: inset 0px -15px 1000px 0px rgba(111, 0, 216, 1);
  -moz-box-shadow: inset 0px -15px 1000px 0px rgba(111, 0, 216, 1);
  box-shadow: inset 0px -15px 1000px 0px rgba(111, 0, 216, 1);
}

/* ================ CARDS BLOG ================ */

.card {
  position: relative;
  list-style: none;
  gap: 60px;
  width: 100%;
  transition: .3s ease;
  cursor: pointer;
}

.card:before {
  content: "";
  display: block;
  width: 100%;
  height: 35vh;
}

@media screen and (max-width: 1024px) {
  .card-background {
    background-size: cover;
    background-position: center;
    border-radius: 24px;
    bottom: 0;
    filter: brightness(0.9) saturate(1.2) contrast(0.85);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: center;
    transform: scale(1) translateZ(0);
    transition:
      filter 200ms linear,
      transform 200ms linear;
  }

  .card:hover .card-background {}

  .card-container:hover .card:not(:hover) {}

  .card-container:hover>.card:not(:hover) .card-category {}
}

@media screen and (min-width: 1024px) {
  .card-background {
    background-size: cover;
    background-position: center;
    border-radius: 24px;
    bottom: 0;
    filter: brightness(0.9) saturate(1.2) contrast(0.85);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: center;
    transform: scale(1) translateZ(0);
    transition:
      filter 200ms linear,
      transform 200ms linear;
    overflow: hidden;
  }

  .card:hover .card-background {
    transform: scale(1.05) translateZ(0);
    border: 1px #6f00d8 solid;
  }
}


.card-category {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 5px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.card-heading {
  color: #ffffff;
  font-size: 1.5rem;
  text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
}

/* ================ BLOG ================ */

.postHtml ol {
  list-style-type: disc;
  padding-left: 25px;
}

.postHtml ul {
  list-style-type: disc;
  padding-left: 25px;
}

.postHtml img {
  width: 100%;
  height: auto;
  margin: 20px 0px;

}

.postHtml p {
  text-align: justify;
  line-height: 32px;
  font-size: 20px;
}

.postHtml h2 {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 37px;
  font-weight: 500;
  line-height: 1.3em;
  font-family: LuxeUnoRegular !important;
}

.postHtml h3 {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 35px;
  font-weight: 500;
  line-height: 1.3em;
  font-family: LuxeUnoRegular !important;
}

.postHtml strong {
  font-family: LuxeUnoRegular !important;
}

/* ================ DOCUMENTS ================ */

.documentsBox h2 {
  font-size: 1.7rem;
  margin: 15px 0px;
}

.documentsBox ul {
  list-style-type: disc;
  padding-left: 25px;
}

.documentsBox {
  color: #e6e6e6
}

/* ================ MOVING BORDER ================ */

.fancy {
  --offset: 3px;
  overflow: hidden;
}

.fancy::before {
  content: '';
  background: conic-gradient(transparent 270deg, #6f00d8, transparent);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1;
  width: 100%;
  animation: rotate 2s linear infinite;
}

.fancy::after {
  content: '';
  background: inherit;
  border-radius: inherit;
  position: absolute;
  inset: var(--offset);
  height: calc(100% - 2 * var(--offset));
  width: calc(100% - 2 * var(--offset));
}

.fancy-white {
  --offset: 3px;
  overflow: hidden;
}

.fancy-white::before {
  content: '';
  background: conic-gradient(transparent 270deg, #ffffff, transparent);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1;
  width: 100%;
  animation: rotate 2s linear infinite;
}

.fancy-white::after {
  content: '';
  background: inherit;
  border-radius: inherit;
  position: absolute;
  inset: var(--offset);
  height: calc(100% - 2 * var(--offset));
  width: calc(100% - 2 * var(--offset));
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) scale(1.4) rotate(0turn);
  }

  to {
    transform: translate(-50%, -50%) scale(1.4) rotate(1turn);
  }
}

/* ================ CAROUSEL ================ */

.slick-dots li button:before {
  color: #6f00d8 !important;
  font-size: 12px !important;
  transform: translatey(20px) !important;
}

/* ================ GLASSMORPHISM ================ */

.glassBox {
  background: rgba(255, 255, 255, 0.04);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

/* ================ SVGs ================ */

.firstSvg svg .svg-elem-1 {
  stroke-dashoffset: 194px;
  stroke-dasharray: 194px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}

.firstSvg svg.active .svg-elem-1 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}

.firstSvg svg .svg-elem-2 {
  stroke-dashoffset: 214px;
  stroke-dasharray: 214px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
    fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
    fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
}

.firstSvg svg.active .svg-elem-2 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}

.firstSvg svg .svg-elem-3 {
  stroke-dashoffset: 117.18843841552734px;
  stroke-dasharray: 117.18843841552734px;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
    fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
    fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
}

.firstSvg svg.active .svg-elem-3 {
  stroke-dashoffset: 0;
}

.firstSvg svg .svg-elem-4 {
  stroke-dashoffset: 38.0499267578125px;
  stroke-dasharray: 38.0499267578125px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
    fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
    fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
}

.firstSvg svg.active .svg-elem-4 {
  stroke-dashoffset: 0;
  fill: rgb(111, 0, 216);
}


.secondSvg svg .svg-elem-1 {
  stroke-dashoffset: 2530.349365234375px;
  stroke-dasharray: 2530.349365234375px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}

.secondSvg svg.active .svg-elem-1 {
  stroke-dashoffset: 0;
  fill: rgb(111, 0, 216);
}

.secondSvg svg .svg-elem-2 {
  stroke-dashoffset: 1356.85302734375px;
  stroke-dasharray: 1356.85302734375px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
    fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
    fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
}

.secondSvg svg.active .svg-elem-2 {
  stroke-dashoffset: 0;
  fill: rgb(111, 0, 216);
}

.secondSvg svg .svg-elem-3 {
  stroke-dashoffset: 400.86798095703125px;
  stroke-dasharray: 400.86798095703125px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
    fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
    fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
}

.secondSvg svg.active .svg-elem-3 {
  stroke-dashoffset: 0;
  fill: rgb(111, 0, 216);
}

.secondSvg svg .svg-elem-4 {
  stroke-dashoffset: 422.6993408203125px;
  stroke-dasharray: 422.6993408203125px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
    fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
    fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
}

.secondSvg svg.active .svg-elem-4 {
  stroke-dashoffset: 0;
  fill: rgb(111, 0, 216);
}

.secondSvg svg .svg-elem-5 {
  stroke-dashoffset: 410.2247009277344px;
  stroke-dasharray: 410.2247009277344px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s,
    fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
  transition: stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s,
    fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
}

.secondSvg svg.active .svg-elem-5 {
  stroke-dashoffset: 0;
  fill: rgb(111, 0, 216);
}

.thirdSvg svg .svg-elem-1 {
  fill: transparent;
  -webkit-transition: fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  transition: fill 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}

.thirdSvg svg.active .svg-elem-1 {
  fill: rgb(111, 0, 216);
}

.forestSvg svg .svg-elem-1 {
  stroke-dashoffset: 748.8314208984375px;
  stroke-dasharray: 748.8314208984375px;
  -webkit-transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
  transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
}

.forestSvg svg.active .svg-elem-1 {
  stroke-dashoffset: 0;
}

/* ================ MODAL ================ */

@keyframes showModal {
  from {
    transform: rotateY(-90deg);
    opacity: 0;
  }
  to {
    transform: rotateY(0);
    opacity: 1;
  }
}

.animate-show-modal {
  animation: showModal 0.5s forwards;
}
